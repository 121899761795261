<template>
  <div class="records-upload">
    <header>
      <h4>Загрузка файла</h4>
      <button title="свернуть" @click="isMain = !isMain">
        <ExpandMore />
      </button>
      <button title="закрыть" @click="$emit('on-upload-close')">
        <Close />
      </button>
    </header>
    <main v-if="isMain">
      <ul>
        <li v-for="({ name, progress, isCompleted }, key) in files" :key="key">
          <span>{{ name }}</span>
          <div class="upload-progress-done">
            <Progress v-if="!isCompleted" :value="progress" />
            <DoneWhite v-else />
          </div>
        </li>
      </ul>
    </main>
  </div>
</template>

<script>
import Close from '@/pic/svg/close'
import DoneWhite from '@/pic/svg/done'
import ExpandMore from '@/pic/svg/expand-more'
import Progress from '../progress/progress'
export default {
  name: 'upload',
  components: { Close, ExpandMore, Progress, DoneWhite },
  props: {
    files: { type: Object }
  },
  data() {
    return {
      isMain: true
    }
  },
  watch: {
    files: {
      handler() {
        this.isMain = true
      },
      deep: true
    }
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
