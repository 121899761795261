import axios from 'axios'
// import { API_URL } from '@/const'
import { store } from '@/store'

export const getAxiosVideo = async () => {
  const { accessToken } = store.state.app.user
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  }
  try {
    const { data } = await axios.get('http://upload.vp.mts-corp.ru/records/v1', config)
    if (!data) return []
    if (data.length === 0) return []
    return data
  } catch (e) {
    console.log(e.response.data)
  }
  return []
}
