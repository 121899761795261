import axios from 'axios'
// import { API_URL } from '@/const'
import { store } from '@/store'

export const postAxiosVideo = (file, id, progress, addVideo) => {
  const { accessToken } = store.state.app.user
  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent
    let percent = Math.floor((loaded * 100) / total)
    progress(percent, id)
  }
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${accessToken}`
    },
    onUploadProgress
  }
  const formData = new FormData()
  formData.append('file', file)
  axios
    .post('http://upload.vp.mts-corp.ru/records/v1', formData, config)
    .then((e) => addVideo(id, e.data))
    .catch((err) => console.log(err.response.data))
}
