<template>
  <div class="records-del">
    <div class="records-del-pl">
      <header>
        <h4>Вы уверены что хотите удалить записи?</h4>
      </header>
      <br />
      <ul>
        <li v-for="({ name }, index) in records" :key="index">{{ name }}</li>
      </ul>
      <br />
      <footer>
        <button @click="$emit('on-close-btn-del')">отмена</button>
        <button @click="$emit('on-ok-btn-del')">ок</button>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'records-del',
  props: {
    records: { type: Object }
  }
}
</script>

<style lang="scss">
@import './styles';
</style>
