<template>
  <div class="records-wrap">
    <Records
      v-if="isRecordsDel"
      :records="records"
      :isRecordsDel="isRecordsBtnDel"
      @on-sel-rec-id="onSelRecId"
      @on-del-records="onDelRecords"
      @on-add-video="onAddVideo"
    />
    <RecordsDel
      v-else
      :records="recordsToDel"
      @on-close-btn-del="onCloseBtnDel"
      @on-ok-btn-del="onOkBtnDel"
    />
  </div>
</template>

<script>
import Records from './record'
import RecordsDel from './comp/records-del/records-del'
import { getAxiosVideo, addToDelRecords, postAxiosDelVideoFile } from './func'
import { getRecordsToDel } from './func'
export default {
  name: 'index-record',
  components: { Records, RecordsDel },
  data() {
    return {
      isRecordsDel: true,
      records: {}
    }
  },
  methods: {
    onSelRecId(id) {
      this.records[id].isCheck = !this.records[id].isCheck
    },
    onDelRecords() {
      this.isRecordsDel = false
    },
    onAddVideo(item) {
      this.records = { ...this.records, [item.id]: item }
    },
    onCloseBtnDel() {
      this.isRecordsDel = true
    },
    onOkBtnDel() {
      this.onCloseBtnDel()
      Object.keys(this.recordsToDel).forEach((v) => {
        const { id, name } = this.recordsToDel[v]
        postAxiosDelVideoFile({ id, name }).then(({ id }) => {
          if (!id) return
          const delItems = { ...this.records }
          delete delItems[id]
          this.records = delItems
        })
      })
    }
  },
  computed: {
    recordsToDel() {
      return getRecordsToDel(this.records)
    },
    isRecordsBtnDel() {
      return !!Object.keys(this.recordsToDel).length
    }
  },
  async mounted() {
    const item = await getAxiosVideo()
    this.records = addToDelRecords(item)
  }
}
</script>

<style lang="scss">
@import './styles';
</style>
