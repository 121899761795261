<template>
  <table class="records-table">
    <thead>
      <tr>
        <th></th>
        <th>Название</th>
        <th>Дата</th>
        <th>Размер</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="r in records" :key="r.id" @click="onClick(r.id)">
        <td @click.stop.prevent="$emit('on-sel-rec-id', r.id)">
          <mts-checkbox :value="r.isCheck" />
        </td>
        <td>{{ r.name }}</td>
        <td>{{ getTimeDate(r.date) }}</td>
        <td>{{ getSize(r.size) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { getTimeDate } from '@/utils/'
import { getSize } from './func'

export default {
  name: 'records-table',
  props: {
    records: { type: Object }
  },
  methods: {
    getTimeDate(date) {
      return getTimeDate(date)
    },
    onClick(id) {
      console.log(id)
    },
    getSize(size) {
      return getSize(size)
    }
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
