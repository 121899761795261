<template>
  <div class="page-records">
    <RecTable :records="records" @on-sel-rec-id="$emit('on-sel-rec-id', $event)" />
    <Footer
      :is-records-del="isRecordsDel"
      @on-video-file="onVideoFile"
      @on-del-records="$emit('on-del-records')"
    />
    <Upload v-if="isShowUpload" @on-upload-close="isShowUpload = false" :files="files" />
  </div>
</template>

<script>
import RecTable from './comp/records-table/records-table'
import Footer from './comp/footer/footer'
import Upload from './comp/upload/upload'
import { postAxiosVideo } from './func'
export default {
  name: 'records',
  components: { RecTable, Footer, Upload },
  props: {
    records: { type: Object },
    isRecordsDel: { type: Boolean }
  },
  data() {
    return {
      isShowUpload: false,
      files: {}
    }
  },
  methods: {
    setProgressFile(p, id) {
      this.files[id].progress = p
    },
    addVideoFile(id, item) {
      const _file = { [id]: { ...this.files[id], isCompleted: true } }
      this.files = { ...this.files, ..._file }
      this.$emit('on-add-video', item)
    },
    onVideoFile(file) {
      this.isShowUpload = true

      const _key = Date.now()
      const _file = { name: file.name, progress: 0 }
      this.files = { ...this.files, [_key]: _file }

      postAxiosVideo(file, _key, this.setProgressFile, this.addVideoFile)
    }
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
