<template>
  <div class="circular-progress" :style="{ background }" />
</template>

<script>
export default {
  name: 'progress-bar',
  props: {
    value: { type: Number }
  },
  computed: {
    background() {
      return `conic-gradient(
      #4d5bf9 ${this.value * 3.6}deg,
      #cadcff ${this.value * 3.6}deg
  )`
    }
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
