<template>
  <div class="records-footer">
    <label for="upload" class="mts-button_theme_dark">
      <Upload />
      <span>Добавить видео</span>
      <input
        type="file"
        id="upload"
        accept="video/mp4,video/x-m4v,video/*"
        placeholder="Введите название"
        @change="onChange"
      />
    </label>
    <button
      :class="{ 'btn--enable': !isRecordsDel }"
      @click.prevent="$emit('on-del-records')"
      :disabled="!isRecordsDel"
    >
      <Delete />
      <span>Удалить</span>
    </button>
  </div>
</template>

<script>
import Delete from '@/pic/svg/delete'
import Upload from '@/pic/svg/upload-file'
export default {
  name: 'records-footer',
  components: { Delete, Upload },
  props: {
    isRecordsDel: { type: Boolean }
  },
  methods: {
    onChange({ target: { files } }) {
      this.$emit('on-video-file', files[0])
    }
  }
}
</script>
<style lang="scss">
@import './styles';
</style>
